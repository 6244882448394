import React from 'react';
import styled, { css } from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import theme from '../layouts/theme';
import { phone } from '../layouts/media';
import { Box } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { H6 } from '../ui';

class MusicCard extends React.Component {
  render() {
    const { post, index, totalPosts } = this.props;
    const titleId = post.frontmatter.title.replace(/\s/g, '');
    return (
      <Box id={titleId} width={'100%'} px={[2, 0]} >
        <Card href={post.frontmatter.spotifyLink} target="_blank">
          <Box>
            <PlayIcon mr={5}>
              <FontAwesomeIcon icon={['fas', 'play']} size="xs"/>
            </PlayIcon>
          </Box>
          <Box>
            <H6 color={theme.colors.white}>
              #{totalPosts - index} {post.frontmatter.title}
            </H6>
          </Box>
        </Card>
      </Box>
    );
  }
}

const PlayIcon = styled(Box)`
  font-size: 38px;
  color: ${props => props.theme.colors.gray[0]};
`;

const Card = styled(OutboundLink)`
  display: flex;
  text-decoration: none;
  align-items: center;
  width: 100%;
  max-width: 378px;
  height: 94px;
  max-height: 102px;
  border: 1px solid transparent;
  border-radius: 24px;
  padding: 4px 32px;

  background: ${props => props.theme.colors.blackish70};

  &:hover {
    border: 1px solid ${props => props.theme.colors.beige};
  }

  ${phone(css`
    height: 80px;
  `)};
`;

export default MusicCard;
