import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled, { css } from "styled-components";
import { Box } from "rebass";
import { AnimationUp, H3, TextBody1 } from "../ui";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";

import theme from "../layouts/theme";
import { phone, tablet, laptop } from "../layouts/media";
import { randomNumber } from "../utils/helpers";

import Nav from "../components/nav/Nav";
import MusicCard from "../components/MusicCard";
import Layout from "../components/layout";

const description = "Inspira-te com a música partilhada por quem adora treinar";
const url = "https://gymlovers.pt";

export const musicPageQuery = graphql`
  {
    image: file(relativePath: { eq: "work-harder.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "interview-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            image
            spotifyLink
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            templateKey
            color
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;

const MusicPage = ({ data, location }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout location={location} interview={posts[randomNumber(posts.length)]}>
      <MusicPageTemplate image={data.image} posts={posts} />
    </Layout>
  );
};

const MusicPageTemplate = ({ posts, image }) => (
  <MusicWrapper>
    <Helmet>
      <title>Música | Gymlovers</title>
      <meta name="description" content={description} />

      <meta itemprop="name" content="Música | Gymlovers" />
      <meta itemprop="description" content={description} />
      <meta itemprop="image" content={`${url}${getSrc(image)}`} />

      <meta property="og:url" content={`${url}/musica/`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Música | Gymlovers" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${url}${getSrc(image)}`} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Música | Gymlovers" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${url}${getSrc(image)}`} />
    </Helmet>
    <BackgroundImage
      style={{ position: "absolute" }}
      image={image.childImageSharp.gatsbyImageData}
    />
    <Nav inverted={"true"} />
    <GradientBlock>
      <GradientContainer>
        <Box px={[0, 0, 0, 2, 2, 1]}>
          <AnimationUp seconds={["1.5s", "2s"]}>
            <H3 textAlign="center" color={theme.colors.beige}>
              Dá power aos teus treinos com a música partilhada pelos Gymlovers
            </H3>
            <TextBody1 textAlign="center" color={theme.colors.whitish}>
              Ao carregares no play irá ser aberto o spotify com a playlist
              escolhida
            </TextBody1>
          </AnimationUp>
        </Box>
      </GradientContainer>
    </GradientBlock>
    <Box bg="black" pb={[5, 6]} pt={[3, 4, 4, 5, 5]} px={[3, 4]}>
      <AnimationUp seconds={["1.5s", "2s"]}>
        <>
          <Grid>
            {posts.map(({ node: post }, index) => (
              <MusicCard
                key={post.id}
                post={post}
                totalPosts={posts.length}
                index={index}
              />
            ))}
          </Grid>
        </>
      </AnimationUp>
    </Box>
  </MusicWrapper>
);

const MusicWrapper = styled.div`
  position: relative;
  z-index: 1;

  background-color: black;
`;

const BackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: 80vh;

  z-index: -1;

  ${laptop(css`
    height: 820px;
  `)};

  ${phone(css`
    height: 512px;
  `)};

  & > picture {
    & > img {
      object-position: center 37% !important;

      ${laptop(css`
        height: 69% !important;
        object-position: center 0% !important;
      `)};

      ${phone(css`
        height: 70% !important;
        object-position: 39% center !important;
      `)};
    }
  }
`;

const GradientBlock = styled(Box)`
  width: 100%;

  margin-top: 474px;

  background: linear-gradient(
    180.88deg,
    rgba(0, 0, 0, 0) 32.49%,
    #000000 96.74%
  );

  ${laptop(css`
    margin-top: 320px;
  `)};

  ${tablet(css`
    margin-top: 300px;
  `)};

  ${phone(css`
    margin-top: 160px;
  `)};
`;

const GradientContainer = styled.div`
  position: relative;
  max-width: 800px;
  margin: auto;
`;

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 52px;
  grid-row-gap: 80px;

  position: relative;

  max-width: 1200px;
  margin: auto;

  z-index: 10;

  ${laptop(
    css`
      grid-column-gap: 32px;
      grid-row-gap: 60px;
      justify-items: center;
      margin: 0;
    `
  )};

  ${laptop(
    css`
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 32px;
      grid-row-gap: 50px;
      justify-items: center;
      margin: 0;
    `
  )};

  ${phone(css`
    grid-template-columns: 1fr;
    grid-row-gap: 40px;

    position: inherit;

    padding-top: 20px;
  `)};
`;

export default MusicPage;
